import axios from 'axios';
import { isExpired, removeItemsFromStorage } from '../utils/tokenFunctions';

const api = axios.create({
    baseURL: 'https://api.biosante.org',
})

api.interceptors.request.use(async (config) => {
    if (
        !config.url.endsWith('login') ||
        !config.url.endsWith('refresh') ||
        !config.url.endsWith('signup')
      ) {
        
        if (isExpired(localStorage.getItem('token'))) {
          config.headers.Authorization = refreshToken();
        } else {
          config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }
      }
    
      return config;
    }, (error) => {
      // I cand handle a request with errors here
      return Promise.reject(error);
    }
);

async function refreshToken(){
  const response = await api.post('/auth/refresh', {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if(response.status == 200){
      localStorage.setItem('token', response.access_token);
  }
  return 'Bearer '.response.access_token;
}

export default api;