import React, { useState } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import Table from './components/Table';

export default function Shipment(){
    return (
        <TemplateIn>
            <PageTitle  
                title='Cotação de Frete'
                subtitle=''    
            />
            <div className='content'>  
                <Table />
            </div>
                   
        </TemplateIn>
    );
}