import React, { useState } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import Table from './components/Table';
import Modal from './components/Modal';

export default function shipping(){
    
    return (
        <TemplateIn>
            <PageTitle  
                title="Fretes"   
            />
            <div className="content" style={{backgroundColor:'#F5F5F5'}}>
                
                    <Table/>
                    {/* <Modal className='show'/> */}
            </div>
                   
        </TemplateIn>
    );
}
