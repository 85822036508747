import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import './styles.css';
export default function Table({total, setTotal}){
    const [load, setLoad] = useState(false);
    const [show, setShow] = useState(true);
    const [peer_page, setPeerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(0);
    const [data, setData] = useState([]);

    async function loadData(){
        setLoad(true);
        api.get(`user?page=${current_page}&peer_page=${peer_page}`)
        .then(function(response) {
           if(response.status == 200){
               setTotal(response.data.meta.total);
               setLastPage(response.data.meta.last_page);
               setData(response.data.data);
               setLoad(false);
           } 
                
        });
    }

    function genaretePagination(){
        let result = [];
        for (let index = 1; index < last_page; index++) {
            result.push(<li key={index} className={'page-item '+((index == current_page) ? 'active' : '')} aria-current='page' onClick={() => setCurrentPage(index)} ><span className='page-link'>{index}</span></li>);            
        }
        return result;
    }

    useEffect(()=>{
        loadData();
    }, [current_page])
    return(
        <div className='row'>
            <div className='col-12'>
                <div className='block'>
                    <div className='block-header block-header-rtl'>
                        <div className='block-options'>
                            {(show) ? (
                                <button type='button' className='btn-block-option' data-toggle='block-option' data-action='content_toggle' onClick={() => setShow(!show)}><i className='si si-arrow-up'></i></button>
                            ) : (
                                <button type='button' className='btn-block-option' data-toggle='block-option' data-action='content_toggle' onClick={() => setShow(!show)}><i className='si si-arrow-down'></i></button>
                            )}
                        </div>
                    </div>
                    {(show) ? (
                        <div className='block-content'>
                            <Formik
                                initialValues={{
                                    email:'',
                                    name:''
                                }}
                                validationSchema={yup.object().shape({
                                    email: yup.string()
                                        .email('Formato de e-mail inválido'),
                                    name: yup.string()
                                })}
                                onSubmit={ async (values)  =>  {
                                    let data = {};
                                    Object.keys(values).forEach((key) => {
                                        if(values[key] !== '')
                                            data[key] = values[key];
                                    });
                                    console.log(data);
                                    const response = await api.post('/user/filter',data);

                                    if(response.status == 200){
                                        setTotal(response.data.meta.total);
                                        setLastPage(response.data.meta.last_page);
                                        setCurrentPage(response.data.meta.current_page);
                                        setData(response.data.data);
                                    }
                                }}
                                render={({ errors, values, status, touched, setErrors}) => (
                                    <Form>
                                        
                                        <div className='form-group form-row'>
                                            <div className='form-group col-3'>
                                                <label className='label'>Nome</label>
                                                <Field type='text' name='name' className={'form-control ' + (errors.name && touched.name ? 'is-invalid' : '') }/>
                                            </div>
                                            <div className='form-group col-3'>
                                                <label className='label'>E-mail</label>
                                                <Field type='text' name='email' className={'form-control ' + (errors.email && touched.email ? 'is-invalid' : '') }/>
                                                {(errors.email) ? (
                                                    <div className="invalid-feedback" role="alert">
                                                        {errors.email}
                                                    </div>
                                                ) : (null) }
                                            </div>
                                        </div>

                                        <div className='form-group form-row'>
                                            <div className='form-group col-7'>
                                                <div className='row items-push'>
                                                    <div className='col-xl-12'>
                                                        <button type='submit' className='btn btn-alter btn-sm btn-primary'>Pesquisar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                            )}/> 
                        </div>
                    ) : (null)}
                    
                </div>
            </div>
            <div className='col-12'>
                <div className='block'>
                    <div className='block-content'>
                        {(load) ? (
                            <div className="text-center mb-3">
                                <div className="spinner-border text-center" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ): (
                            <div>
                                <table className='table table-bordered '>
                            <thead>
                                <tr>
                                    <th># </th>
                                    <th>NOME</th>
                                    <th>E-MAIL</th>
                                    <th>TIPO</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {(data.length > 0) ? (
                                
                                data.map(function(item, key) {
                                    return(
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.role}</td>
                                            <td>
                                                <a href={`/users/${item.id}`}>
                                                    <i style={{fontSize: '22px'}} className="far fa-edit"></i>
                                                </a> 
                                            </td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan='5' className='text-center'>Nenhum registro encontrado</td>
                                    </tr>
                                )}
                                
                            </tbody>
                        </table>
                        {(last_page > 1) ?
                        (
                        <ul className='pagination' role='navigation'>
                            <li className={'page-item ' + ((current_page <= 1) ? 'disabled' : '')} onClick={() => setCurrentPage(current_page-1)} aria-disabled='true' aria-label='« Anterior'>
                                <span className='page-link' aria-hidden='true'>‹</span>
                            </li>
                            
                            {genaretePagination()}

                            <li className='page-item'>
                                <a className='page-link' onClick={() => setCurrentPage(current_page+1)} rel='next' aria-label='Próximo »'>›</a>
                            </li>
                        </ul>
                        )  :
                        (null) 
                    }
                            </div>
                        )}
                        
                       
                    </div>
                </div>
            </div>
        </div>
    );
}
