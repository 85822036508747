import React from 'react';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import { useHistory } from 'react-router-dom';
import './styles.css';

import '../../template/theme/css/oneui.css';

import logo from '../../assets/logo-lisa.png';
import { useStateValue } from '../../states/ContextProvider';
import api from '../../services/api';

export default function Auth() {
    const [, dispatch] = useStateValue();
    const history = useHistory();
    var bcrypt = require('bcryptjs');
    return (
        <div id="page-container">
        <main id="main-container">
            <div className="bg-dark">
                <div className="hero-static bg-white-70">
                    <div className="content">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-4">
                           
                            <div className="row center">
                                <img src={logo} className="img-style img-center img-responsive img-fluid"/>
                            </div>
                                <div className="block block-themed mb-0">

                                    <div  style={{background: '#666669'}}  className="block-header">
                                        <h3 className="block-title">Acesso ao Sistema</h3>
                                    </div>
                                    <div className="block-content">
                                        <div className="p-sm-3 px-lg-4">
                                            <h2 className="mb-2">Entrar</h2>
                                                <Formik
                                                    initialValues={{
                                                        email: '',
                                                        password: ''
                                                    }}
                                                    validationSchema={yup.object().shape({
                                                        email: yup.string()
                                                            .email('Formato de e-mail inválido')
                                                            .required('É necessário infromar o E-mail'),
                                                        password: yup.string()
                                                            .required('É necessário infromar a senha')
                                                    })}
                                                    onSubmit={async (values, {setErrors}) =>{
                                                       api.post('/auth',values).then(function(response){
                                                            history.push('/');
                                                            dispatch({
                                                                type: 'login',
                                                                token: response.data.access_token,
                                                                user_name: response.data.name,
                                                                user_role: bcrypt.hashSync(response.data.role, '$2a$10$2SIpaDad5FcVol0sx0Eak.') 
                                                            })
                                                       }).catch(function(error){
                                                            if(error.response.status == 422)
                                                                setErrors(error.response.data.errors);
                                                       });
                                                    }}
                                                    render={({ errors, values, touched , setErrors}) => (
                                                        <Form >
                                                            <div className="py-3">
                                                                <div className="form-group">
                                                                    <Field name="email" type="text" placeholder="E-mail" className={'form-control form-control-alt form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                                        {(errors.email) ? (
                                                                            <div className="invalid-feedback" role="alert">
                                                                                {errors.email}
                                                                            </div>
                                                                        ) : (null) }
                                                                </div>
                                                                <div className="form-group">
                                                                    <Field name="password" type="password" placeholder="Senha" className={'form-control form-control-alt form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                                        {(errors.password) ? (
                                                                            <div className="invalid-feedback" role="alert">
                                                                                {errors.password}
                                                                            </div>
                                                                        ) : (null) }
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <div className="col-md-6 col-xl-5">
                                                                    <button type="submit" className="btn btn-sm btn-block btn-darkgrey">
                                                                        <i className="fa fa-fw fa-sign-in-alt mr-1"></i> Acessar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content content-full font-size-sm text-muted text-center">
                    </div>
                </div>
            </div>
        </main>
    </div>
    );
}