import React from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';

export default function Wellcome(){
    return(
        <TemplateIn>
             <PageTitle  
                title='Bem vindo!'
            />
        </TemplateIn>
    )
}