import React,{useEffect} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';
import { StateProvider } from '../src/states/ContextProvider';

function App() {
  const initialState = { token: '', user_name: '', user_role: ''};

  function updateToken(token, user_name, role){
    if((token !== null && token !== '') &&
      (user_name !== null && user_name !== '') &&
      (role !== null && role !== '')){
        localStorage.setItem('token', token);
        localStorage.setItem('user_name', user_name);
        localStorage.setItem('user_role', role);

    }else{
      localStorage.removeItem('token');
      localStorage.removeItem('user_name');
      localStorage.removeItem('user_role');
    }
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'login':
        updateToken(action.token, action.user_name, action.user_role);
        return {
          ...state,
          token: action.token,
          user_name: action.user_name,
          user_role: action.user_role
        };
      case 'logout':
        updateToken('', '', '');
        return {
          ...state,
          token: '',
          user_name: '',
          user_role: ''
        };
        default:
          return state;
    }
  }

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Routes/>
    </StateProvider>
  );
}

export default App;