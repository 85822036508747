import React, { useState, useEffect } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import api from '../../services/api';

export default function Profile(){
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    async function loadData() {
        setLoading(true);
        api.get('/auth/me').then(function(response){
            if(response.status == 200){
                setData(response.data);
                setLoading(false);
            }
        }).catch(function(errors) {
            console.log(errors);
        })
    }
    useEffect(() => {
        loadData();
    }, [])
    return(
        <TemplateIn>
            <PageTitle  
                title='Meus Dados'
                subtitle='Informações sobre seu perfil'  
            />
            <div className="content">
                <div className='row'>
                    <div className='col-12'>
                        {(message !== '' && message !== null) ? (
                            <div className="alert alert-error alert-success" role="alert">
                                 <p className="mb-0">{message}</p>
                            </div>
                        ):(null)}
                       
                        <div className='block'>
                            <div className='block-content'>
                                <div className='row push'>
                                    <div className='col-lg-12'>
                                        {(loading) ? (
                                            <div className="text-center">
                                                <div className="spinner-border text-center" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <Formik
                                                initialValues={{
                                                    id: data.id,
                                                    name: data.name,
                                                    email: data.email,
                                                    password: ''
                                                }}
                                                validationSchema={yup.object().shape({
                                                    password: yup.string()
                                                        .required('É necessário informar a senha')
                                                        .min(6),
                                                })}
                                                onSubmit={async (values) => {
                                                    setLoading(true);
                                                    api.put('/user', values)
                                                        .then(function(response){
                                                            if(response.status == 200){
                                                                setMessage(response.data.message);
                                                                setLoading(false);
                                                            }
                                                        }).catch(function(errors){
                                                            console.log(errors);
                                                            setLoading(false);
                                                        })
                                                }}
                                            
                                                render={({ errors, values, status, touched, setErrors}) => (
                                                    <Form>
                                                        <div className="form-row pb-3">
                                                            <div className="form-group col-xl-3">
                                                                <label>Nome</label>
                                                                <Field type='text' name='name' disabled className='form-control'/>
                                                            </div>
                                                            <div className="form-group col-xl-3">
                                                                <label>E-mail</label>
                                                                <Field type='text' name='email' disabled className='form-control'/>
                                                            </div>
                                                            <div className="form-group col-xl-3">
                                                                <label>Senha</label>
                                                                <Field type='password' name='password' className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                                {(errors.password) ? (
                                                                    <div className="invalid-feedback" role="alert">
                                                                        {errors.password}
                                                                    </div>
                                                                ) : (null) } 
                                                            </div>
                                                        </div>
                                                        <div className='form-group form-row'>
                                                            <div className='form-group col-7'>
                                                                <div className='row items-push'>
                                                                    <div className='form-group  col-xl-12'>
                                                                        <button type='submit' className='btn btn-sm btn-success'>
                                                                {(loading) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : (null) }
                                                                            Salvar Informações</button> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                            )}/> 
                                        )}
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TemplateIn>
    );
}