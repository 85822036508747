import React from 'react';
export default function PageTitle({title, subtitle}) {
    return (
            <div className="bg-body-light">
                <div className="content content-full">
                    <div className="flex-sm-fill">
                        <h3 className="font-w600 mb-0">{title}</h3>
                        <h5 className="h6 font-w400 mb-0">{subtitle}</h5>
                    </div>
                </div>
            </div>
    );
}