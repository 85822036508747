import React from 'react';
import { useStateValue } from '../../../../states/ContextProvider';
import { useHistory, useLocation } from 'react-router-dom';
import { replace } from 'formik';

export default function LeftNavbar(){
    const [state] = useStateValue();
    const history = useHistory();
    const location = useLocation();
    var bcrypt = require('bcryptjs');

    function canAccess(role){
        if(bcrypt.compareSync(role, state.user_role))
            return true;
        return false;
    }
    function active(route){
        if(route == location.pathname.replace('/', ''))
            return 'active';
        else 
            return '';
    }
    return(
        <ul className="nav-main">
            <li className="nav-main-item">
                <a className={"nav-main-link " + active('')} href="/">
                    <i className="nav-main-link-icon fa fa-home"></i>
                    <span className="nav-main-link-name">Início</span>
                </a>
            </li>
            <li className="nav-main-item">
                <a className={"nav-main-link " + active('perfil')} href="/perfil">
                    <i className="nav-main-link-icon fa fa-id-badge"></i>
                    <span className="nav-main-link-name">Meus Dados</span>
                </a>
            </li>
            {(!canAccess('atendente')? (
                <li className="nav-main-item">
                    <a className={"nav-main-link " + active('orders')} href="/orders">
                        <i className="nav-main-link-icon fa fa-shopping-basket"></i>
                        <span className="nav-main-link-name">Pedidos</span>
                    </a>
                </li>
            ):(null))}
             <li className="nav-main-item">
                <a className={"nav-main-link " + active('frete')} href="/frete">
                    <i className="nav-main-link-icon fa fa-map-marked-alt"></i>
                    <span className="nav-main-link-name">Consultar Frete</span>
                </a>
            </li>
            <li className="nav-main-item">
                <a className={"nav-main-link " + active('csv')} href="/csv">
                    <i className="nav-main-link-icon fa fa-copy"></i>
                    <span className="nav-main-link-name">Camparação de vendas</span>
                </a>
            </li>
            {(canAccess('admin ')? (
            <div>
                <li className="nav-main-item">
                    <a className={"nav-main-link " + active('users')} href="/users">
                        <i className="nav-main-link-icon fa fa-users"></i>
                        <span className="nav-main-link-name">Usuários</span>
                    </a>
                </li>
                <li className="nav-main-item">
                    <a className={"nav-main-link " + active('shipping')} href="/shipping">
                        <i className="nav-main-link-icon fa fa-users"></i>
                        <span className="nav-main-link-name">Frete</span>
                    </a>
                </li>
            </div>
            ) : (null))}
        </ul>
    );
}