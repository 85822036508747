import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import { useHistory } from "react-router-dom";
import './styles.css';

export default function Table(){
    let history = useHistory();
    const [show, setShow] = useState(true);
    const [peer_page, setPeerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(0);
    const [data, setData] = useState([]);

    function genaretePagination(){
        let result = [];
        for (let index = 1; index < last_page; index++) {
            result.push(<li key={index} className={'page-item '+((index == current_page) ? 'active' : '')} aria-current='page' onClick={() => setCurrentPage(index)} ><span className='page-link'>{index}</span></li>);            
        }
        return result;
    }

    return(
        <div className='row'>
            <div className='col-12'>
                <div className='block'>
                    {(show) ? (
                        <div className='block-content'>
                            <Formik
                                initialValues={{
                                    cep: '',
                                }}
                                validationSchema={yup.object().shape({
                                    cep: yup.string()
                                        .required('É necessário informar o cep'),
                                })}
                                onSubmit={ async (values)  =>  {
                                    const response = await api.get(`/shipping/${values.cep}`);

                                    if(response.status == 200){
                                        setData(response.data.data);
                                    }
                                    // console.log(response);
                                }}
                                render={({ errors, values, status, touched, setErrors}) => (
                                    <Form>
                                        <div className='form-group form-row'>
                                            <div className='form-group col-3'>
                                                <label>CEP</label>
                                                <Field type='text' name='cep' className='form-control'/>
                                            </div>
                                        </div>

                                        <div className='form-group form-row'>
                                            <div className='form-group col-7'>
                                                <div className='row items-push'>
                                                    <div className='form-group  col-xl-12'>
                                                        <button type='submit' className='btn btn-sm btn-success'>Pesquisar</button>
                                                        <a onClick={() => { setData([]) }} className='btn btn-sm btn-warning ml-2'>Limpar</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                            )}/>  
                        </div>
                    ):(null)}
                    
                </div>
            </div>
            {(data.length > 0) ? (
                <div className='col-12'>
                    <div className='block'>
                        <div className='block-content'>
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>REGIÃO </th>
                                        <th>CEP INICIAL</th>
                                        <th>CEP FINAL</th>
                                        <th>PESO INICIAL</th>
                                        <th>PESO FINAL</th>
                                        <th>VALOR FRETE</th>
                                        <th>PRAZO ENTREGA</th>
                                        <th>TIPO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(data.length > 0) ? (
                                    
                                    data.map(function(item, key) {
                                        return(
                                            <tr key={key}>
                                                <td>{item.regiao}</td>
                                                <td>{item.cep_inicial}</td>
                                                <td>{item.cep_final}</td>
                                                <td>{item.peso_inicial}</td>
                                                <td>{item.peso_final}</td>
                                                <td>{item.valor_frete}</td>
                                                <td>{item.prazo_entrega}</td>
                                                <td>{item.type}</td>
                                            </tr>
                                        )
                                    })) : (
                                        <tr>
                                            <td colSpan='8' className='text-center'>Nenhum registro encontrado</td>
                                        </tr>
                                    )}
                                    
                                </tbody>
                            </table>
                            {(last_page > 1) ?
                            (
                            <ul className='pagination' role='navigation'>
                                <li className={'page-item ' + ((current_page <= 1) ? 'disabled' : '')} onClick={() => setCurrentPage(current_page-1)} aria-disabled='true' aria-label='« Anterior'>
                                    <span className='page-link' aria-hidden='true'>‹</span>
                                </li>
                                
                                {genaretePagination()}

                                <li className='page-item'>
                                    <a className='page-link' onClick={() => setCurrentPage(current_page+1)} rel='next' aria-label='Próximo »'>›</a>
                                </li>
                            </ul>
                            )  :
                            (null) 
                        }
                        
                        </div>
                    </div>
                </div>
            ) : (null)}
            
        </div>
    );
}
