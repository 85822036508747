import React, { useState } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import Table from './components/Table';

export default function Order(){
    const [total, setTotal] = useState(0);
    return (
        <TemplateIn>
            <PageTitle  
                title="Pedidos"
                subtitle={(total == 0) ? 'Nenhum registro encontrado' : total + ' registros encontrados'}    
            />
            <div className="content" style={{backgroundColor:'#F5F5F5'}}>
                
                    <Table total={total} setTotal={setTotal}/>
            </div>
                   
        </TemplateIn>
    );
}
