import React, { useState } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import api from '../../services/api';
import { useParams, useHistory } from "react-router-dom";
import { isInteger } from 'formik';
import './styles.css';

export default function User(){
    let history = useHistory();
    
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    return(
        <TemplateIn>
            <PageTitle  
                title='Adicionar novo Usuário'
                subtitle=''  
            />
            <div className="content">
                <div className='row'>
                    <div className='col-12'>
                        {(message !== '' && message !== null) ? (
                            <div className="alert alert-error alert-success" role="alert">
                                 <p className="mb-0">{message}</p>
                            </div>
                        ):(null)}
                       
                        <div className='block'>
                            <div className='block-content'>
                                <div className='row push'>
                                    <div className='col-lg-12'>
                                    <Formik
                                                initialValues={{
                                                    name: '',
                                                    email: '',
                                                    role: '',
                                                    password: ''
                                                }}
                                                validationSchema={yup.object().shape({
                                                    name: yup.string()
                                                        .required('É necessário informar o nome'),
                                                    email: yup.string()
                                                        .email()
                                                        .required('É necessário informar o E-mail'),
                                                    role: yup.string()
                                                        .required('É necessário informar o Tipo'),
                                                    password: yup.string()
                                                        .required('É necessário informar a senha')
                                                        .min(6),
                                                })}
                                                onSubmit={async (values, {setErrors}) => {
                                                   

                                                    setLoading(true);
                                                    api.post('/user', values)
                                                        .then(function(response){
                                                            console.log(response.status)
                                                            if(response.status == 200){
                                                                history.push('/users');
                                                                setLoading(false);
                                                            }
                                                        }).catch(error => {
                                                            console.log(error.response)
                                                            if(error.response.status == 422){
                                                                    setErrors(error.response.data.errors)
                                                                setLoading(false);
                                                        }
                                                        })
                                                }}>
                                                {({ 
                                                    errors,
                                                    values,
                                                    status,
                                                    touched,
                                                    setErrors,
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    isSubmitting
                                                }) => (
                                                    <Form>
                                                        <div className="form-row pb-3">
                                                            <div className="form-group col-xl-3">
                                                                <label>Nome <span style={{color:'indianred'}}>*</span></label>
                                                                <Field type='text' name='name' className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                                                {(errors.name) ? (
                                                                    <div className="invalid-feedback" role="alert">
                                                                        {errors.name}
                                                                    </div>
                                                                ) : (null) }
                                                            </div>
                                                            <div className="form-group col-xl-3">
                                                                <label>E-mail <span style={{color:'indianred'}}>*</span></label>
                                                                <Field type='text' name='email' className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                                {(errors.email) ? (
                                                                    <div className="invalid-feedback" role="alert">
                                                                        {errors.email}
                                                                    </div>
                                                                ) : (null) }
                                                            </div>
                                                            <div className="form-group col-xl-3">
                                                                <label>Senha <span style={{color:'indianred'}}>*</span></label>
                                                                <Field type='password' name='password' className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                                {(errors.password) ? (
                                                                    <div className="invalid-feedback" role="alert">
                                                                        {errors.password}
                                                                    </div>
                                                                ) : (null) } 
                                                            </div>
                                                            <div className="form-group col-xl-3">
                                                                <label>Tipo <span style={{color:'indianred'}}>*</span></label>
                                                                <Field type='text' as='select' name='role' className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')}>
                                                                    <option value=''>Selecionar</option>
                                                                    <option value='admin'>Administrador</option>
                                                                    <option value='user'>Usuário</option>
                                                                    <option value='atendente'>Atendente</option>
                                                                </Field>
                                                                {(errors.role) ? (
                                                                    <div className="invalid-feedback" role="alert">
                                                                        {errors.role}
                                                                    </div>
                                                                ) : (null) } 
                                                            </div>
                                                        </div>
                                                        <div className='form-group form-row'>
                                                            <div className='form-group col-7'>
                                                                <div className='row items-push'>
                                                                    <div className='form-group  col-xl-12'>
                                                                        <button type='submit' className='btn btn-sm btn-primary'>
                                                                {(loading) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : (null) }
                                                                            Salvar Informações</button> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                            )}
                                        </Formik>   
                                       
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TemplateIn>
    );
}