import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import Order from './pages/Order';
import Shipment from './pages/Shipment';
import Shipping from './pages/Shipping';
import Profile from './pages/Profile';
import EditOder from './pages/EditOder';
import User from './pages/User';
import EditUser from './pages/EditUser';
import NewUser from './pages/NewUser';
import Wellcome from './pages/Wellcome';
import CsvCompare from './pages/CsvCompare';

import LoadingStorage from './pages/LoadingStorage';

import { useStateValue } from './states/ContextProvider';

export default function Routes() {
    const NotFoundRedirect = () => <Redirect  to='/not-found' />
    const [state] = useStateValue();
    var bcrypt = require('bcryptjs');

    function hasRole(role){
        if(bcrypt.compareSync(role, state.user_role))
            return true;
        return false;
    }

    function generateRoutes(){
        if(hasRole('admin ')){
            return <Switch>
                <Route exact path="/" component={ Order }/> 
                <Route path="/orders" exact component={ Order } />
                <Route path="/orders/:id" exact component={ EditOder } />
                <Route path="/frete" exact component={ Shipment } />
                <Route path="/shipping" exact component={ Shipping } />
                <Route path="/perfil" exact component={ Profile } />
                <Route path="/csv" exact component={ CsvCompare } />
                <Route path="/users" exact component={ User } />
                <Route path="/user/new" exact component={ NewUser } />
                <Route path="/users/:id" exact component={ EditUser } />
                <Route path='404' component={ NotFound } />
                <Route path='*' component={ NotFound } />
            </Switch>
        }else if(hasRole('user ')){
            return <Switch>
                <Route exact path="/" component={ Order }/> 
                <Route path="/orders" exact component={ Order } />
                <Route path="/orders/:id" exact component={ EditOder } />
                <Route path="/frete" exact component={ Shipment } />
                <Route path="/shipping" exact component={ Shipping } />
                <Route path="/perfil" exact component={ Profile } />
                <Route path='404' component={ NotFound } />
                <Route path='*' component={ NotFound } />
            </Switch>
        }
        else{
            return <Switch>
                <Route exact path="/" component={ Wellcome }/> 
                <Route path="/frete" exact component={ Shipment } />
                <Route path="/shipping" exact component={ Shipping } />
                <Route path="/perfil" exact component={ Profile } />
                <Route path='404' component={ NotFound } />
                <Route path='*' component={ NotFound } />
            </Switch>
            }   
    }
    return (
        <BrowserRouter>
            
            {(state.token == "" || state.token == null) ? (
            <Switch>
                <React.Fragment>
                    <Route path="/" component={ LoadingStorage } /> 
                    <Route path="/login" component={ Auth } />    
                </React.Fragment> 
            </Switch>
            
            ) : (
                generateRoutes()
            )}
        </BrowserRouter>
    );
}
