import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import  { useHistory } from 'react-router-dom'
import './styles.css';
export default function Table({total, setTotal}){
    const history = useHistory();
    const [show, setShow] = useState(true);
    const [peer_page, setPeerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(0);
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);

    async function loadData(){
        setLoad(true);
        api.get(`orders?page=${current_page}&peer_page=${peer_page}`)
        .then(function(response) {
           if(response.status == 200){
               setTotal(response.data.total);
               setLastPage(response.data.last_page);
               setData(response.data.data);
               setLoad(false);
           } 
        }).catch(function(errors) {
            console.log(errors);
            setLoad(false);
        });
    }

    function genaretePagination(){
        let result = [];
        for (let index = current_page; index < current_page+10; index++) {
            result.push(<li key={index} className={'page-item '+((index == current_page) ? 'active' : '')} aria-current='page' onClick={() => setCurrentPage(index)} ><span className='page-link'>{index}</span></li>);            
        }
        return result;
    }
    function redirect(id){
        console.log(id)
       history.push(`/orders/${id}`);
    }

    useEffect(()=>{
        loadData();
    }, [current_page])
    return(
        <div className='row'>
            <div className='col-12'>
                <div className='block'>
                    <div className='block-header block-header-rtl'>
                        <div className='block-options'>
                            {(show) ? (
                                <button type='button' className='btn-block-option' data-toggle='block-option' data-action='content_toggle' onClick={() => setShow(!show)}><i className='si si-arrow-up'></i></button>
                            ) : (
                                <button type='button' className='btn-block-option' data-toggle='block-option' data-action='content_toggle' onClick={() => setShow(!show)}><i className='si si-arrow-down'></i></button>
                            )}
                        </div>
                    </div>
                    {(show) ? (
                        <div className='block-content'>
                            <Formik
                                initialValues={{
                                    status: '',
                                    paymentDate:'',
                                    verified: '',
                                    email:'',
                                    cpf:'',
                                    customer:''
                                }}
                                validationSchema={yup.object().shape({
                                    email: yup.string()
                                        .email('Formato de e-mail inválido'),
                                })}
                                onSubmit={ async (values)  =>  {
                                    let data = {};
                                    Object.keys(values).forEach((key) => {
                                        if(values[key] !== '')
                                            data[key] = values[key];
                                    });
                                    setLoad(true);
                                    const response = await api.post('/orders/filter',data);

                                    if(response.status == 200){
                                        setTotal(response.data.meta.total);
                                        setLastPage(response.data.meta.last_page);
                                        setCurrentPage(response.data.meta.current_page);
                                        setData(response.data.data);
                                        setLoad(false);
                                    }
                                    // console.log(response);
                                }}
                                render={({ errors, values, status, touched, setErrors}) => (
                                    <Form>
                                        <div className='form-group form-row'>
                                            <div className='form-group col-3'>
                                                <label>Data de Pagamento</label>
                                                <Field type='date' name='paymentDate' className='form-control'/>
                                            </div>
                                            <div className='form-group col-3'>
                                                <label>Status</label>
                                                <Field type='text' as='select' name='status' className='form-control'>
                                                    <option value=''>Selecione</option>
                                                    <option value='paid'>Paid</option>
                                                    <option value='cancelled'>Cancelled</option>
                                                </Field>
                                            </div>
                                            <div className='form-group col-3'>
                                                <label>Verified</label>
                                                <Field type='text' as='select' name='verified' className='form-control'>
                                                    <option value=''>Selecione</option>
                                                    <option value='1'>Sim</option>
                                                    <option value='-1'>Não</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className='form-group form-row'>
                                            <div className='form-group col-3'>
                                                <label>E-mail</label>
                                                <Field type='text' name='email' className='form-control'/>
                                            </div>
                                            <div className='form-group col-3'>
                                                <label>CPF</label>
                                                <Field type='text' name='cpf' className='form-control'/>
                                            </div>
                                            <div className='form-group col-3'>
                                                <label>Nome</label>
                                                <Field type='text' name='customer'  className='form-control'/>
                                            </div>
                                        </div>

                                        <div className='form-group form-row'>
                                            <div className='form-group col-7'>
                                                <div className='row items-push'>
                                                    <div className='col-xl-12'>
                                                        <button type='submit' className='btn btn-alter btn-sm btn-primary'>Pesquisar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                            )}/>  
                        </div>
                    ):(null)}
                    
                </div>
            </div>
            <div className='col-12'>
                <div className='block'>
                    <div className='block-content'>
                        {(load) ? (
                            <div className="text-center mb-3">
                                <div className="spinner-border text-center" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className='table-responsive'>
                                <table className='table table-bordered table-striped'>
                            <thead>
                                <tr>
                                    <th># </th>
                                    <th>CLIENTE</th>
                                    <th>STATUS</th>
                                    <th>DATA CRIAÇÃO</th>
                                    <th>CPF</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {(data.length > 0) ? (
                                
                                data.map(function(item, key) {
                                    return(
                                        <tr key={key} className='pointer' onClick={() => redirect(item.id)}>
                                            <th className='sorting_1'>
                                                <a key={key} href={`/orders/${item.id}`}> {item.id} </a>
                                            </th>
                                            <td>{item.customer_name}</td>
                                            <td>{item.status}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.customer_cpf}</td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td colSpan='5' className='text-center'>Nenhum registro encontrado</td>
                                    </tr>
                                )}
                                
                            </tbody>
                        </table>
                        {(last_page > 1) ?
                        (
                        <ul className='pagination' role='navigation'>
                            <li className={'page-item ' + ((current_page <= 1) ? 'disabled' : '')} onClick={() => setCurrentPage(current_page-1)} aria-disabled='true' aria-label='« Anterior'>
                                <span className='page-link' aria-hidden='true'>‹</span>
                            </li>
                            
                            {genaretePagination()}

                            <li className='page-item'>
                                <a className='page-link' onClick={() => setCurrentPage(current_page+1)} rel='next' aria-label='Próximo »'>›</a>
                            </li>
                        </ul>
                        )  :
                        (null) 
                        }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
