import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';
import { useHistory } from "react-router-dom";
import './styles.css';
export default function Table(){
    const [show, setShow] = useState(true);
    const [total, setTotal] = useState(0);
    const [peer_page, setPeerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(0);
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);

    async function loadData(){
        setLoad(true);
        api.get(`shipping?page=${current_page}&peer_page=${peer_page}`)
        .then(function(response) {
           if(response.status == 200){
               setTotal(response.data.meta.total);
               setLastPage(response.data.meta.last_page);
               setData(response.data.data);
               setLoad(false);
           } 
        }).catch(function(errors) {
            console.log(errors);
            setLoad(false);
        });
    }

    function genaretePagination(){
        let result = [];
        for (let index = current_page; index < current_page+10; index++) {
            result.push(<li key={index} className={'page-item '+((index == current_page) ? 'active' : '')} aria-current='page' onClick={() => setCurrentPage(index)} ><span className='page-link'>{index}</span></li>);            
        }
        return result;
    }

    useEffect(()=>{
        loadData();
    }, [current_page])
    return(
        <div className='row'>
            <div className='col-12'>
                <div className='block'>
                    <div className='block-content'>
                    {(load) ? (
                        <div className="text-center mb-3">
                             <div className="spinner-border text-center" role="status">
                                 <span className="sr-only">Loading...</span>
                             </div>
                        </div>
                         ) : (
                        
                            <div>
                                <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>REGIÃO </th>
                                        <th>CEP INICIAL</th>
                                        <th>CEP FINAL</th>
                                        <th>PESO INICIAL</th>
                                        <th>PESO FINAL</th>
                                        <th>VALOR FRETE</th>
                                        <th>PRAZO ENTREGA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(data.length > 0) ? (
                                    
                                    data.map(function(item, key) {
                                        return(
                                            <tr key={key}>
                                                <td>{item.regiao}</td>
                                                <td>{item.cep_inicial}</td>
                                                <td>{item.cep_final}</td>
                                                <td>{item.peso_inicial}</td>
                                                <td>{item.peso_final}</td>
                                                <td>{item.valor_frete}</td>
                                                <td>{item.prazo_entrega}</td>
                                            </tr>
                                        )
                                    })) : (
                                        <tr>
                                            <td colSpan='8' className='text-center'>Nenhum registro encontrado</td>
                                        </tr>
                                    )}
                                    
                                </tbody>
                            </table>
                            {(last_page > 1) ?
                                (<ul className='pagination' role='navigation'>
                                    <li className={'page-item ' + ((current_page <= 1) ? 'disabled' : '')} onClick={() => setCurrentPage(current_page-1)} aria-disabled='true' aria-label='« Anterior'>
                                        <span className='page-link' aria-hidden='true'>‹</span>
                                    </li>
                                    
                                    {genaretePagination()}

                                    <li className='page-item'>
                                        <a className='page-link' onClick={() => setCurrentPage(current_page+1)} rel='next' aria-label='Próximo »'>›</a>
                                    </li>
                                </ul>)  :
                                (null) 
                            }
                            </div>
                     
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
}
