export function parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  }

export function isExpired(token) {
    if (token) {
      var decode = parseJwt(token)
      const expiry = decode.exp;
      const now = new Date();
      return now.getTime() > expiry * 1000;
    }
    return false;
}

export function removeItemsFromStorage(){
    localStorage.clear('token');
    localStorage.clear('user_name');
}