import React from 'react';
import TemplateIn from '../../template/TemplateIn';


export default function NotFound(){
    return (
        <TemplateIn>
            <div className="content">
                    <h3>Não encontrado</h3>
            </div>

        </TemplateIn>
    );
}
