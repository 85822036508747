import React, { useState } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import * as XLSX from 'xlsx';

export default function CsvCompare(){
    const [load, setLoad] = useState(false);
    const [block, setBlock] = useState(false);
    const [label, setLabel] = useState('selecione o arquivo..');
    const [label2, setLabel2] = useState('selecione o arquivo..');
    
    const [dataCsv, setDataCsv] = useState(null); //bling
    const [dataCsvToCompare, setDataCsvToCompare] = useState(null); // pagar.me

    const [result, setResult] = useState([]);

    function handleInput(e) {
        e.preventDefault();
        if(!e.target.files[0])
            return;
        
        setLabel(e.target.files[0].name);

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          processData(data, 1);
        };
        reader.readAsBinaryString(file);
        

    }

    function handleInput2(e) {
        e.preventDefault();
        if(!e.target.files[0])
            return;

        setLabel2(e.target.files[0].name);

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          processData(data, 2);
        };
        reader.readAsBinaryString(file);
    }

    function processData (dataString, data) {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
     
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
          const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
          if (headers && row.length == headers.length) {
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
              let d = row[j];
              if (d.length > 0) {
                if (d[0] == '"')
                  d = d.substring(1, d.length - 1);
                if (d[d.length - 1] == '"')
                  d = d.substring(d.length - 2, 1);
              }
              if (headers[j]) {
                obj[headers[j]] = d;
              }
            }
     
            // remove the blank rows
            if (Object.values(obj).filter(x => x).length > 0) {
              list.push(obj);
            }
          }
        }
     
        // prepare columns list from headers
        const columns = headers.map(c => ({
          name: c,
          selector: c,
        }));
        
        if(data === 1){
            setDataCsv({
                columns:columns,
                list:list
            });
        }else{
            setDataCsvToCompare({
                columns:columns,
                list:list
            });
        }
    }
    function analyze(data){
        if(dataCsv){

            var aux = dataCsv.list.find(obj => {
                return (obj['Cpf/Cnpj'] == data['Documento'])
            })
           
            if(aux === undefined){
                return false;
            }

            return true;
        }
    }
    function compare(){
        setLoad(true);
        setBlock(true);
        var array = [];
        if(dataCsv && dataCsvToCompare){
            dataCsvToCompare.list.map((item, key) => {
                var aux = analyze(item);
                if(!aux)
                    array.push(item);
            });
            setResult(array);
            
        }
        setTimeout(function(){
            setLoad(false);
        }, 2000);
           
    }
    function utf8Decode(utf8String) {
        if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
        // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
        const unicodeString = utf8String.replace(
            /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
            function(c) {  // (note parentheses for precedence)
                var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
                return String.fromCharCode(cc); }
        ).replace(
            /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
            function(c) {  // (note parentheses for precedence)
                var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
                return String.fromCharCode(cc); }
        );
        return unicodeString;
    }
    
    function limpar() {
        setLabel('selecione o arquivo..');
        setLabel2('selecione o arquivo..');
        setDataCsv(null);
        setDataCsvToCompare(null);
        setBlock(false);
        this.form.reset()
    }
    return (
        <TemplateIn>
            <PageTitle  
                title={`Camparação de Vendas`}
            />
            <div className="content" style={{backgroundColor:'#F5F5F5'}}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='block'>
                            <div className='block-content'>
                                
                                <form>
                                    <div className='col-12 text-right'>
                                        <button className='btn btn-danger' onClick={() => limpar()} disabled={load}> Limpar </button>
                                    </div>
                                    <div className='form-group form-row'>
                                        <div className='form-group col-6'>
                                            <label>CSV Bling</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" accept=".csv,.xlsx,.xls" className="custom-file-input" placeholder='selecione o arquivo..' disabled={block} onChange={handleInput}/>
                                                    <label className="custom-file-label" htmlFor="inputGroupFile04">{label}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group col-6'>
                                            <label>CSV Pagar.me</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" accept=".csv,.xlsx,.xls" className="custom-file-input" disabled={block} id="inputGroupFile02" onChange={handleInput2}/>
                                                    <label className="custom-file-label" htmlFor="inputGroupFile04">{label2}</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </form>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <button className='btn btn-success'  onClick={() => compare()} disabled={block}> Comparar </button>
                                    </div>
                                    {(result.length > 0) ? (
                                        <div className='col-12 '>
                                            {(load) ? (
                                                <div className="text-center mb-3">
                                                    <div className="spinner-border text-center" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <b className=''>Resultados não encontrados no csv do Bling ({result.length}):</b>
                                                    <table className='mt-2 table table-bordered table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th>Data</th>
                                                                <th>Nome do contato</th>
                                                                <th>Documento</th>
                                                                <th>E-mail</th>
                                                                <th>Valor</th>
                                                                <th>Forma de Pagamento</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>    
                                                            {result.map((item, key) => (
                                                                <tr key={key}>
                                                                    <td>{item['Data']}</td>
                                                                    <td>{utf8Decode(item['Nome'])}</td>
                                                                    <td>{item['Documento']}</td>
                                                                    <td>{item['Email']}</td>
                                                                    <td>{item['Valor']}</td>
                                                                    <td>{utf8Decode(item['Forma de Pagamento'])}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                 ) : (null)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TemplateIn>
    )
}