import React, { useEffect }  from 'react';
import { useStateValue } from '../../states/ContextProvider';
import { useHistory } from 'react-router-dom';
import { isExpired, removeItemsFromStorage } from '../../utils/tokenFunctions';

export default function LoadingStorage(){
    const [, dispatch] = useStateValue();
    const history = useHistory();

    function getStorageData(){
        let data = {};
        data.user_name = localStorage.getItem('user_name');
        data.token = localStorage.getItem('token');
        data.user_role = localStorage.getItem('user_role');


        if(isExpired(data.token)){
            removeItemsFromStorage();
            dispatch({
                type:'logout'
            });
            history.push('/login');
        }else{           
            if(data.token == null || data.token == '')
                return history.push('/login');

            dispatch({
                type: 'login',
                token: data.token,
                user_name: data.user_name,
                token_expires: data.token_expires,
                user_role: data.user_role
            });
        }
    }
     
    useEffect(() => {
        getStorageData();
    }, []);

    return(
        <div>
        </div>
    );
}