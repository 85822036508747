import React from 'react';
import '../theme/css/app.css';
import '../theme/css/oneui.css';
import './style.css';

import Footer from './components/Footer';
import LeftNavbar from './components/LeftNavbar';
import { useStateValue } from '../../states/ContextProvider';
import api from '../../services/api';
import logo from '../../assets/logo-lisa.png';

export default function TemplateIn({ children }) {
    const [state, dispatch] = useStateValue();

    async function logout(){
        const response = await api.post('/auth/logout');
        if(response.status == 200)
            dispatch({
                type:'logout'
            })
    }
    return (
    
        <div id="page-container" className="sidebar-o enable-page-overlay sidebar-dark side-scroll page-header-fixed">
            <nav id="sidebar" aria-label="Main Navigation">
                <div  className="content-header bg-white-5 text-center">
                    <a className="font-w600 text-dual" href="/">
                        <span className="font-w700 font-size-h5">
                            <img src={logo}  width="48%" className="img-responsive img-fluid"/>
                        </span>
                    </a>
                </div>
    
                <div className="content-side content-side-full">
                    <LeftNavbar/>
                </div>
            </nav>
    
            <header id="page-header">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <button type="button" className="btn btn-sm btn-dual mr-2 d-lg-none" data-toggle="layout" data-action="sidebar_toggle">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
    
                        <button type="button" className="btn btn-sm btn-dual mr-2 d-none d-lg-inline-block" data-toggle="layout" data-action="sidebar_mini_toggle">
                            <i className="fa fa-fw fa-ellipsis-v"></i>
                        </button>
    
                    </div>
    
    
    
                    <div className="d-flex align-items-center">
                        <div className="dropdown d-inline-block mr-2">
                            <a className="btn btn-sm btn-default">
                                <i className="si si-user mr-1"></i>
                                <span>{ state.user_name }</span>
                            </a>
                        </div>
                        <div className="dropdown d-inline-block ml-2">
                            <a className="btn btn-sm btn-dual mr-2" onClick={() => logout()}>
                                <span>Sair</span>
                                <i className="si si-logout ml-1"></i>
                            </a>
                        </div>
    
                    </div>
                </div>
    
                <div id="page-header-search" className="overlay-header bg-white">
                    <div className="content-header">
                        <form className="w-100" action="/dashboard" method="POST">
                            @csrf
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn btn-danger" data-toggle="layout" data-action="header_search_off">
                                        <i className="fa fa-fw fa-times-circle"></i>
                                    </button>
                                </div>
                                {/* <input type="text" className="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input"> */}
                            </div>
                        </form>
                    </div>
                </div>
    
                <div id="page-header-loader" className="overlay-header bg-white">
                    <div className="content-header">
                        <div className="w-100 text-center">
                            <i className="fa fa-fw fa-circle-notch fa-spin"></i>
                        </div>
                    </div>
                </div>
            </header>
    
            <main id="main-container">
                { children }
            </main>
    
            {/* <Footer/> */}
    
          
        </div>
    );
}