import React, { useEffect, useState } from 'react';
import TemplateIn from '../../template/TemplateIn';
import PageTitle from '../../template/TemplateIn/components/PageTitle';
import {
    BrowserRouter as Router,
    useParams,
    useHistory
} from "react-router-dom";
import api from '../../services/api';
import './styles.css';
import { isInteger } from 'formik';
import * as yup from 'yup';
import { Formik,  Field, Form  } from 'formik';

export default function EditOrder({}){
    const { id } = useParams();
    let history = useHistory();
    const [order, setOrder] = useState({});
    const [load, setLoad] = useState(true);
    const [antifraude, setAntifraude] = useState(null);
    async function loadData() {
        setLoad(true);

        api.get(`orders/${id}`)
        .then(function(response){
            setOrder(response.data.data);
            if(response.data.data.anti_fraud !== null){
                setAntifraude(JSON.parse(response.data.data.anti_fraud.value));
            }
            setLoad(false);

        })
        .catch(function(errors) {
            console.log(errors);
        });
    }
    function check(data, dataCompare){
        if(data.toLowerCase() == dataCompare.toLowerCase())
            return (
                <span className="badge badge-success">
                    <svg className="bi bi-check" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                    </svg>
                </span>
            )
        else
            return (
                <span className="badge badge-danger">&times;</span>
            )
    }
    function dateFormate(date){
        let nova = new Date(date);
        return nova.getFullYear() + '-' +(nova.getMonth()+1).toString().padStart(2, '0')+'-'+nova.getUTCDate().toString().padStart(2, '0');
    }
  
    useEffect(() =>{
        if(!isInteger(id))
            history.push('/404');
        else
            loadData();
    },[]);

    /** Verificar se o receiver e diferente do customer e retorna true */
    function checkReceiver(value, valueToCompare){
        if(value == null || valueToCompare == null)
            return false;

        if(value.toLowerCase() == valueToCompare.toLowerCase())
            return false;

        return true;
    }
    function generateStatus(status){
        switch (status) {
            case 'paid':
                return( <span className="badge badge-pill badge-success">{status}</span>)
            case 'waiting_payment':
                return( <span className="badge badge-pill badge-warning">{status}</span>)
        }
    }

    function isEmpty(obj){
        return Object.keys(obj).length === 0;
    }
    return (
        <TemplateIn>
            <PageTitle  
                title={`Editar Pedido (#${id})`}
            />
            <div className="content" style={{backgroundColor:'#FFFF'}}>
                <div className='row push'>
                    <div className='col ml-3'>
                        {(load) ? (
                            <div className="text-center">
                               <div className="spinner-border text-center" role="status">
                                   <span className="sr-only">Loading...</span>
                               </div>
                           </div>
                        ) : 
                        (
                            <div className='row'>
                                <div className='col-12 col-md-6 col-lg-6'>
                                    <h3>Cliente</h3>
                                    <div>
                                        Nome: <b>{order.customer.name}</b>
                                    </div>
                                    <div>
                                        CPF: <b>{order.customer.cpf}</b>
                                    </div>
                                    <div>
                                        Nome da Mãe: <b>{(antifraude) ? (antifraude.Result[0].BasicData.MotherName) : (null)}</b>
                                    </div>
                                </div>
                                {(order.transaction !== null) ? (
                                    
                                        (order.transaction.billet_url !== '') ? (
                                            <div className='col-12 col-md-6 col-lg-6'>
                                                <h3>Dados de Pagamento ({order.transaction.payment_alias})</h3>
                                                <div>
                                                    <a href={order.transaction.billet_url} target='_blank' className='badge badge-primary'>
                                                        Url Boleto
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                        <div className='col-12 col-md-6 col-lg-6'>
                                            <h3>Dados de Pagamento ({order.transaction.payment_alias})</h3>
                                            <div>
                                                Nome Cartão: <b>{order.transaction.holder_name}</b>
                                            </div>
                                            <div>
                                                CPF: <b>{order.transaction.holder_document}</b>  {(order.transaction.holder_document !== '') ? (check(order.customer.cpf,order.transaction.holder_document)) : (null)}
                                            </div>
                                        </div>
                                        )
                                    
                                ):(null)}
                                {(order.status == 'paid' && order.transaction == null) ? (
                                    <div className='col-12 col-md-6 col-lg-6'>
                                        <h3>Dados de Pagamento</h3>
                                        <span class="badge badge-warning">
                                            Problemas com os dados contate o administrador
                                                <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                        </span>
                                    </div>
                                ) : (null)}
                                <div className='col-12 col-md-6 col-lg-6 mt-4'>
                                    <h3>Order</h3>
                                    <div>
                                        Produto: {order.items.map(function(element, key){
                                            return (
                                                <b key={key}>{element.product} ({element.quantity} potes)</b>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        Total de Potes: <b>{order.total_items}</b>
                                    </div>
                                    <div>
                                        Status:&nbsp;
                                        {generateStatus(order.status)}
                                        
                                    </div>
                                    {(order.transaction !== null) ? (
                                        <div>
                                            <div>
                                                Data Pagamento: <b>{order.transaction.payment_date}</b>
                                            </div>
                                            <div>
                                                Parcelas: <b>{order.transaction.installments} (Total: {order.total})</b>
                                            </div>
                                        </div>
                                    ) : (null)}
                                    
                                </div>
                                <div className='col-12 col-md-6 col-lg-6 mt-4'>
                                    <h3>Source</h3>
                                    <div className='mb-3'>
                                        IP: <b>{order.ip}</b> &nbsp;
                                        <a href={`https://clicky.com/stats/visitors?site_id=101163122&date=last-28-days&ip_address=${order.ip}`}
                                        target="_blank"
                                        className="btn btn-warning btn-small"
                                        >
                                            Clicky
                                        </a> &nbsp;
                                    </div>
                                    <div>
                                        Tipo (TotalExpress): <b>{order.shipment_type}</b>
                                    </div>
                                </div>
                                <div className='row col-12'>

                                
                                <div className='col-12 col-md-6 col-lg-6 col-sm-12 mt-4'>
                                    <b>Verificado</b>
                                    <div>
                                    <Formik
                                        initialValues={{
                                        verified: order.verified
                                        }}
                                        validationSchema={yup.object().shape({
                                            email: yup.string()
                                                .email('Formato de e-mail inválido'),
                                        })}
                                        onSubmit={ async (values)  =>  {
                                        
                                            api.put('/orders', {
                                                id: order.id,
                                                verified: values.verified
                                            }).then(function(response){
                                                console.log(response);
                                            }).catch(function(errors) {
                                                console.log(errors)
                                            });
                                        }}
                                        render={({ errors, values, status, touched, setErrors}) => (
                                            <Form>
                                                <div className='form-group form-row'>
                                                    <div className='form-group col-12 col-md-6'>
                                                        <Field type='text' as='select' name='verified' className='form-control'>
                                                            <option value='1'>Sim</option>
                                                            <option value='0'>Não</option>
                                                        </Field>
                                                    </div>
                                                    <div className='form-group col-12 col-md-12'>
                                                        <button type='submit' className='btn btn-alter btn-sm btn-success'>Salvar Informações</button>
                                                    </div>
                                                </div>
                                            </Form>
                                    )}/> 
                                        </div>
                                </div>
                                </div>
                                <div className='col-12 mt-5'>
                                        <div>
                                            <div className='table-responsive push'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center"><span className="badge badge-success">Dados Compra</span></th>
                                                    <th className="text-center"><span className="badge badge-info">Dados Antifraude</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width='50%'>
                                                        <div>Name: <b>{order.customer.name}</b></div>
                                                    </td>
                                                    <td width='50%'>
                                                        {(antifraude) ? ( 
                                                        <div>
                                                            <b>{antifraude.Result[0].BasicData.Name} </b>
                                                            {check(order.customer.name,antifraude.Result[0].BasicData.Name)}
                                                        </div>
                                                        ) : (null)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width='50%'>
                                                        <div>Data de Nascimento: <b>{order.customer.birthday}</b></div>
                                                    </td>
                                                    <td width='50%'>
                                                    {(antifraude) ? (
                                                        <div>
                                                            <b>{dateFormate(antifraude.Result[0].BasicData.BirthDate)} </b>
                                                            {check(order.customer.birthday,dateFormate(antifraude.Result[0].BasicData.BirthDate))}
                                                        </div>
                                                    ) : (null)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width='50%'>
                                                        <div>Situação Scoras:</div>
                                                    </td>
                                                    <td width='50%'>
                                                    {(antifraude && antifraude.Result[0].OnlineQueries) ? (
                                                        <div>
                                                            <b>{antifraude.Result[0].OnlineQueries[0].QueryResultData.Score} ({antifraude.Result[0].OnlineQueries[0].QueryResultData.Evaluation}) </b>
                                                        </div>
                                                    ) : (null)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center"><span className="badge badge-success">Email Compra</span></th>
                                                    <th className="text-center"><span className="badge badge-info">Email Antifraude</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width='50%'>
                                                        <p className="font-w600 mb-1">Email</p> <div><b>{order.customer.email}</b></div>
                                                    </td>
                                                    <td width='50%'>
                                                    {(antifraude && (isEmpty(antifraude.Result[0].ExtendedEmails) == false && antifraude.Result[0].ExtendedEmails.TotalEmails > 0)) ? 
                                                       ( antifraude.Result[0].ExtendedEmails.Emails.map(function(element, key) {
                                                             return(<div key={key}>
                                                                        <p className='font-w600 mb-1'>Email #{key+1}</p>
                                                                        <div>
                                                                            <b>{element.EmailAddress} </b>
                                                                            {check(order.customer.email,element.EmailAddress)}
                                                                        </div>
                                                                        {(antifraude.Result[0].ExtendedEmails.TotalEmails >= 1 && key+1 !== antifraude.Result[0].ExtendedEmails.TotalEmails) ? (<hr/>) :(null)}
                                                                        
                                                                    </div>)
                                                        })) : (null)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center"><span className="badge badge-success">PHONES Compra</span></th>
                                                    <th className="text-center"><span className="badge badge-info">PHONES Antifraude</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width='50%'>
                                                    <p className="font-w600 mb-1">Phone #{order.customer.phone.id}</p> 
                                                    <div>
                                                        Number: <b>{order.customer.phone.number}</b>
                                                    </div>
                                                    <div>
                                                    Formated Number: <b>{order.customer.phone.formated_number}</b> 
                                                    <a className="btn btn-small" href={order.customer.phone.whatsapp_link.replace('api', 'web')} target="_blank">
                                                        <img src="https://lisa.biosante.org/whatsapp.svg" width="30px"/>
                                                    </a>
                                                    </div>
                                                    </td>
                                                    <td width='50%'>
                                                        {(antifraude && (isEmpty(antifraude.Result[0].ExtendedPhones) == false && antifraude.Result[0].ExtendedPhones.TotalPhones > 0)) ? 
                                                       ( antifraude.Result[0].ExtendedPhones.Phones.map(function(element, key) {
                                                             return(<div key={key}>
                                                                        <p className='font-w600 mb-1'>Phone #{key+1}</p>
                                                                        <div>
                                                                            Formated Number: 
                                                                            <b>{element.Number} </b>
                                                                            {check(order.customer.phone.number,element.Number)}
                                                                        </div>
                                                                        {(antifraude.Result[0].ExtendedPhones.TotalPhones > 1 && key+1 !== antifraude.Result[0].ExtendedPhones.TotalPhones ) ? (<hr/>) :(null)}
                                                                        
                                                                    </div>)
                                                        })) : (null)}
                                                       
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center"><span className="badge badge-success">Address Compra</span></th>
                                                    <th className="text-center"><span className="badge badge-info">Address Antifraude</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width='50%'>
                                                        <p className="font-w600 mb-1">Address #{order.address.id}</p> 
                                                        <div>
                                                            UF: <b>{order.address.uf}</b>
                                                        </div>
                                                        <div>
                                                            City: <b>{order.address.city}</b>
                                                        </div>
                                                        <div>
                                                            ZipCode: <b>{order.address.zipcode}</b>
                                                        </div>
                                                        <div>
                                                            Number: <b>{order.address.number}</b>
                                                        </div>
                                                        <div className='text-muted'>
                                                            Street: <b>{order.address.street}</b>
                                                        </div>
                                                        <div className='text-muted'>
                                                            Neighborhood: <b>{order.address.neighborhood}</b>
                                                        </div>
                                                        <div className='text-muted'>
                                                            Receiver: <b>{order.address.receiver} </b> 
                                                            {(checkReceiver(order.customer.name, order.address.receiver)) ? ( 
                                                            <span class="badge badge-warning">
                                                                    Entrega com nome diferente
                                                                    <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                                            </span>):(null)}
                                                        </div>
                                                        <div className='text-muted'>
                                                            Complement: <b>{order.address.complement}</b>
                                                        </div>
                                                        <div className='text-muted'>
                                                            Reference: <b>{order.address.reference}</b>
                                                        </div>
                                                        <div className='text-muted'>
                                                            Source: <b>{order.address.source}</b>
                                                        </div>
                                                    </td>
                                                    <td width='50%'>
                                                       {(antifraude && (isEmpty(antifraude.Result[0].ExtendedAddresses) == false)) ? 
                                                       ( antifraude.Result[0].ExtendedAddresses.Addresses.map(function(element, key) {
                                                             return(<div key={key}>
                                                                        <p className='font-w600 mb-1'>Address #{key+1}</p>
                                                                        <div>
                                                                            UF:  
                                                                            <b> {element.State} </b>
                                                                            {check(order.address.uf,element.State)}
                                                                        </div>
                                                                        <div>
                                                                            City:  
                                                                            <b> {element.City} </b>
                                                                            {check(order.address.city,element.City)}
                                                                        </div>
                                                                        <div>
                                                                            ZipCode:  
                                                                            <b> {element.ZipCode} </b>
                                                                            {check(order.address.zipcode,element.ZipCode)}
                                                                        </div>
                                                                        <div>
                                                                            Number:  
                                                                            <b> {element.Number} </b>
                                                                            {check(order.address.number,element.Number)}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                            
                                                                            Street: <b>{element.AddressMain}</b>
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Neighborhood: <b>{element.Neighborhood}</b>
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Complement: <b>{element.Complement}</b>
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Reference: <b></b>
                                                                        </div>
                                                                        {(antifraude.Result[0].ExtendedAddresses.TotalAddresses > 1 && key+1 !== antifraude.Result[0].ExtendedAddresses.TotalAddresses ) ? (<hr/>) :(null)}
                                                                        
                                                                    </div>)
                                                        })) : (null)}
                                                       
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        </div>
                                </div>
                            </div>  
                        )}
                        
                    </div>
                </div>
            </div>
        </TemplateIn>
    );
}
